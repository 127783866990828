import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import lasketiir1 from "../../images/Lasketiir1.jpg"
import lasketiir2 from "../../images/Lasketiir2.jpg"
import lasketiir3 from "../../images/LasketiiruPU põrandWEBeng.png"

const lasketiiru = () => (
<Layout>
    <SEO title="Lasketiiru põrandad" />

    <div className="header-bottom">
      <div className="bg-color-bottom">
        <div className="header-bottom-text">
          <div className="intro-para text-center quote">
            <h1>LASKETIIRU PÕRANDAD</h1>
            <hr className="footer-line"></hr>
          </div>
        </div>
      </div>
    </div>

    <div
      className="epoporandad"
      style={{
        maxWidth: 1300,
        margin: "auto",
      }}
    >
      <div className="wrapper-epo">
        <Link
          to="/epoporandad/antistaatilised/">
          <div className="epo antistaatilised">
            <h2>Antistaatilised</h2>
            <div className="epo-img epo-img1"></div>
          </div>
        </Link>
        <Link to="/epoporandad/epohoordemass/">
          <div className="epo epohoordemass">
            <h2>epohõõrdemass</h2>
            <div className="epo-img epo-img2"></div>
          </div>
        </Link>
        <Link to="/epoporandad/isetasanduv/">
          <div className="epo isetasanduv-epomasskate">
            <h2>isetasanduv epomasskate</h2>
            <div className="epo-img epo-img3"></div>
          </div>
        </Link>
        <Link to="/epoporandad/treppidele/">
          <div className="epo treppidele">
            <h2>treppidele</h2>
            <div className="epo-img epo-img4"></div>
          </div>
        </Link>
        <Link to="/epoporandad/dekoratiivporandad/">
          <div className="epo dekoratiivporandad">
            <h2>dekoratiivpõrandad</h2>
            <div className="epo-img epo-img5"></div>
          </div>
        </Link>
        <Link
          to="/epoporandad/lasketiiru/"
          activeStyle={{ borderBottom: "3px solid #ffa609" }}
        >
          <div className="epo lasketiiru">
            <h2>Lasketiiru põrandad</h2>
            <div className="epo-img epo-img1"></div>
          </div>
        </Link>
      </div>

      <div className="antistaatilised-wrapper">
        <div className="antistaatilised-text">
            <h3>WS PU lasketiiru põrand</h3>
            <p>
            Meie pakutav WS lasketiiru PU põrandakate omab head võimalikku rikošeti vastast kaitset põrkuvate kuulide /
            kildude eest.Kummiplaatide peal olev polüuretaanikiht on vastupidav, umbes 2 mm paksune vuukideta kiht.
            Soovitame kasutada voolujuhtivat ESD PU - katet, mis ei lase pinnal tekkida staatilist elektrit.
            See vähendab lendleva püssirohutolmu ja põrandale sattuva püssirohu süttimise võimalikkust.<br></br>
            Aluskiht koosneb elastsetest SBR - kummiplaatidest.Kummiplaadi standardmõõdud 1 000 x 1000 mm plaadi kohta
            <br></br>
            Mõõtmete tolerantsid: pikkus / laius± 1 % paksus± 2 mm
            </p>
            <h3>WS lasketiiru põrandakatte kogupaksus</h3>
            < p > Must alusmatt 40 mm, PUR - kiht 2 mm;
            soovi korral muud kihipaksused
            Kaal m2 kohta umbes 40 kg / m2</p>
            <h3>Pind</h3>
            < p > Vuukideta sile pind
            PUR - kattega pind saadaval mitmes erinevates värvitoonides(näiteks RAL - kataloog)
            </p>
            <h3>Puhastamine</h3>
            < p > Lihtne puhastada, võimalik kasutada ka märgpuhastust
            </p>
            <h3>Paigaldamine</h3>
            < p > Liimitud tasandatud ja kuivatatud tugevale aluskihile, betoonile või mastiksasfaldile
            </p>
                    
            <hr></hr>
            
            <h3>WS shooting range flooring</h3>
            <p>
            Our WS Shooting range Flooring possesses the best possible anti - ricochet protection from projectiles.
            The layer of polyurethane on top of the tiles provide a hard - wearing ca 2 - mm - thick seamless surface.
            The base layer consists of SBR - rubber elastic tiles.
            <br></br>
            Standard Dimensions 1, 000 x 1000 mm per tile
            Dimensional tolerances: Length / Width± 1 % Thickness± 2 mm
            </p>
            <h3> WS shooting range flooring thickness </h3>
            < p > Black base mat ca 40 mm, PUR layer 2 mm;
            other thicknesses on request
            <br></br>
            Weight per m2approx.40 kg / m2
            </p>
            <h3>Surface</h3>
            <p>Seamless sealed</p>
            <h3>Colour Wear Layer</h3>
            <p>Black base mat, PUR coated surface available in many different Colours.</p>
            <h3>Cleaning</h3>
            <p>Easy to clean, wet cleaning is also possible</p>
            <h3>Installation</h3>
            <p>Glued onto levelled, dried screed or mastic asphalt; other bases on request</p>
                    

        </div>
        < div className = "subpage-img antistaatilised-img" >
            <img
                src={lasketiir1}
                alt="Antistaatilised katted"
                width="400"
            ></img>
        </div>
        < div className = "subpage-img antistaatilised-img" >
            <img
                src={lasketiir2}
                alt="Antistaatilised katted"
                width="400"
            ></img>
        </div>
        < div className = "subpage-img antistaatilised-img" >
            <img
                src={lasketiir3}
                alt="Antistaatilised katted"
                /* width="100" */
            ></img>
        </div>
      </div>
    </div>
  </Layout>
)

export default lasketiiru
